<template>
  <b-container fluid>
      <b-row>
         <b-col md="6" lg="3">
            <iq-card className="iq-card-block iq-card-stretch iq-card-height">
               <template v-slot:body>
                  <div class="d-flex align-items-center">
                     <div class="rounded-circle iq-card-icon bg-primary"><i class="ri-user-line"></i></div>
                     <div class="text-left ml-3">
                        <h2 class="mb-0"><span class="counter">5000</span></h2>
                        <h5 class="">Users</h5>
                     </div>
                  </div>
               </template>
            </iq-card>
         </b-col>
         <b-col md="6" lg="3">
            <iq-card className="iq-card-block iq-card-stretch iq-card-height">
               <template v-slot:body>
                  <div class="d-flex align-items-center">
                     <div class="rounded-circle iq-card-icon bg-danger"><i class="ri-book-line"></i></div>
                     <div class="text-left ml-3">
                        <h2 class="mb-0"><span class="counter">4.8</span>k</h2>
                        <h5 class="">Books</h5>
                     </div>
                  </div>
               </template>
            </iq-card>
         </b-col>
         <b-col md="6" lg="3">
            <iq-card className="iq-card-block iq-card-stretch iq-card-height">
               <template v-slot:body>
                  <div class="d-flex align-items-center">
                     <div class="rounded-circle iq-card-icon bg-warning"><i class="las la-eye"></i></div>
                     <div class="text-left ml-3">
                        <h2 class="mb-0"><span class="counter">1.2</span>k</h2>
                        <h5 class="">Views</h5>
                     </div>
                  </div>
               </template>
            </iq-card>
         </b-col>
         <b-col md="6" lg="3">
            <iq-card className="iq-card-block iq-card-stretch iq-card-height">
               <template v-slot:body>
                  <div class="d-flex align-items-center">
                     <div class="rounded-circle iq-card-icon bg-info"><i class="las la-star"></i></div>
                     <div class="text-left ml-3">
                        <h2 class="mb-0"><span class="counter">690</span></h2>
                        <h5 class="">Favorites</h5>
                     </div>
                  </div>
               </template>
            </iq-card>
         </b-col>
         <!-- <b-col md="4">
            <iq-card className="iq-card-block iq-card-stretch iq-card-height" headerClass="align-items-center">
               <template v-slot:headerTitle>
                     <h4 class="card-title mb-0">Daily Views</h4>
                  </template>
                  <template v-slot:body>
                  <ApexChart element="home-chart-02" :chartOption="chart1" style="min-height: 200px;"/>
               </template>
            </iq-card>
         </b-col>
         <b-col md="4">
            <iq-card className="iq-card-block iq-card-stretch iq-card-height" headerClass="align-items-center">
               <template v-slot:headerTitle>
                     <h4 class="card-title mb-0">Summary</h4>
               </template>
               <template v-slot:body>
                  <ul class="list-inline p-0 mb-0">
                     <li>
                        <div class="iq-details mb-2">
                           <span class="title">Income</span>
                           <div class="percentage float-right text-primary">95 <span>%</span></div>
                           <div class="iq-progress-bar-linear d-inline-block w-100">
                                 <b-progress :value="95" class="iq-progress-bar" variant="primary" />
                           </div>
                        </div>
                     </li>
                     <li>
                        <div class="iq-details mb-2">
                           <span class="title">Profit</span>
                           <div class="percentage float-right text-warning">72 <span>%</span></div>
                           <div class="iq-progress-bar-linear d-inline-block w-100">
                              <b-progress :value="72" class="iq-progress-bar" variant="warning" />
                           </div>
                        </div>
                     </li>
                     <li>
                        <div class="iq-details mb-2">
                           <span class="title">Expenses</span>
                           <div class="percentage float-right text-info">75 <span>%</span></div>
                           <div class="iq-progress-bar-linear d-inline-block w-100">
                              <b-progress :value="75" class="iq-progress-bar" variant="info" />
                           </div>
                        </div>
                     </li>
                  </ul>
               </template>
            </iq-card>
         </b-col>
         <b-col md="4">
            <iq-card className="iq-card-block iq-card-stretch iq-card-height">
                  <template v-slot:body>
                     <h4 class="text-uppercase text-black mb-0">Session(Now)</h4>
                     <div class="d-flex justify-content-between align-items-center">
                        <div class="font-size-80 text-black">12</div>
                        <div class="text-left">
                           <p class="m-0 text-uppercase font-size-12">1 Hours Ago</p>
                           <div class="mb-1 text-black">1500<span class="text-danger"><i class="ri-arrow-down-s-fill"></i>3.25%</span></div>
                           <p class="m-0 text-uppercase font-size-12">1 Day Ago</p>
                           <div class="mb-1 text-black">1890<span class="text-success"><i class="ri-arrow-down-s-fill"></i>1.00%</span></div>
                           <p class="m-0 text-uppercase font-size-12">1 Week Ago</p>
                           <div class="text-black">1260<span class="text-danger"><i class="ri-arrow-down-s-fill"></i>9.87%</span></div>
                        </div>
                     </div>
                    <ApexChart element="wave-chart-7" :chartOption="waveChart7" :isLive="true"  style="min-height: 70px;"/>
                  </template>
            </iq-card>
         </b-col> -->
         <b-col sm="12">
                     <iq-card className="iq-card iq-card-block iq-card-stretch iq-card-height" headerClass="align-items-center position-relative mb-0 trendy-detail" bodyClass="trendy-contens">
                        <template v-slot:headerTitle>
                              <h4 class="card-title mb-0">Trendy Books</h4>
                           </template>
                           <template v-slot:headerAction>
                              <router-link to="/category-page" class="btn btn-sm btn-primary view-more text-white">View More</router-link>
                           </template>
                        <template v-slot:body>
                           <Slick id="trendy-slider" :option="option2">

                                 <li class="d-flex align-items-center">
                                    <div class="col-5 p-0 position-relative image-overlap-shadow">
                                       <a href="javascript:void();"><img class="img-fluid rounded w-100" src="../../assets/images/trendy-books/01.jpg" alt=""></a>
                                       <div class="view-book">
                                          <router-link to="book-page" class="btn btn-sm btn-white">View Book</router-link>
                                       </div>
                                    </div>
                                    <div class="col-7">
                                       <div class="mb-2">
                                          <h6 class="mb-1">The Word Books Day..</h6>
                                          <p class="font-size-13 line-height mb-1">Paul Molive</p>
                                          <div class="d-block">
                                             <span class="font-size-13 text-warning">
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             </span>
                                          </div>
                                       </div>
                                       <!-- <div class="price d-flex align-items-center">
                                          <span class="pr-1 old-price">$99</span>
                                          <h6><b>$89</b></h6>
                                       </div> -->
                                       <div class="iq-product-action">
                                          <!-- <a href="javascript:void();"><i class="ri-shopping-cart-2-fill text-primary"></i></a> -->
                                          <a href="javascript:void();" class="ml-2"><i class="ri-heart-fill text-danger"></i></a>
                                       </div>
                                    </div>
                                 </li>

                                 <li class="d-flex align-items-center">
                                    <div class="col-5 p-0 position-relative image-overlap-shadow">
                                       <a href="javascript:void();"><img class="img-fluid rounded w-100" src="../../assets/images/trendy-books/02.jpg" alt=""></a>
                                       <div class="view-book">
                                          <router-link to="book-page" class="btn btn-sm btn-white">View Book</router-link>
                                       </div>
                                    </div>
                                    <div class="col-7">
                                       <div class="mb-2">
                                          <h6 class="mb-1">The catcher in the Rye</h6>
                                          <p class="font-size-13 line-height mb-1">Anna Sthesia</p>
                                          <div class="d-block">
                                             <span class="font-size-13 text-warning">
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             </span>
                                          </div>
                                       </div>
                                       <!-- <div class="price d-flex align-items-center">
                                          <span class="pr-1 old-price">$89</span>
                                          <h6><b>$79</b></h6>
                                       </div> -->
                                       <div class="iq-product-action">
                                          <!-- <a href="javascript:void();"><i class="ri-shopping-cart-2-fill text-primary"></i></a> -->
                                          <a href="javascript:void();" class="ml-2"><i class="ri-heart-fill text-danger"></i></a>
                                       </div>
                                    </div>
                                 </li>

                                 <li class="d-flex align-items-center">
                                    <div class="col-5 p-0 position-relative image-overlap-shadow">
                                       <a href="javascript:void();"><img class="img-fluid rounded w-100" src="../../assets/images/trendy-books/03.jpg" alt=""></a>
                                       <div class="view-book">
                                          <router-link to="book-page" class="btn btn-sm btn-white">View Book</router-link>
                                       </div>
                                    </div>
                                    <div class="col-7">
                                       <div class="mb-2">
                                          <h6 class="mb-1">Little Black Book</h6>
                                          <p class="font-size-13 line-height mb-1">Monty Carlo</p>
                                          <div class="d-block">
                                             <span class="font-size-13 text-warning">
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             </span>
                                          </div>
                                       </div>
                                       <!-- <div class="price d-flex align-items-center">
                                          <span class="pr-1 old-price">$100</span>
                                          <h6><b>$89</b></h6>
                                       </div> -->
                                       <div class="iq-product-action">
                                          <!-- <a href="javascript:void();"><i class="ri-shopping-cart-2-fill text-primary"></i></a> -->
                                          <a href="javascript:void();" class="ml-2"><i class="ri-heart-fill text-danger"></i></a>
                                       </div>
                                    </div>
                                 </li>

                                 <li class="d-flex align-items-center">
                                    <div class="col-5 p-0 position-relative image-overlap-shadow">
                                       <a href="javascript:void();"><img class="img-fluid rounded w-100" src="../../assets/images/trendy-books/04.jpg" alt=""></a>
                                       <div class="view-book">
                                          <router-link to="book-page" class="btn btn-sm btn-white">View Book</router-link>
                                       </div>
                                    </div>
                                    <div class="col-7">
                                       <div class="mb-2">
                                          <h6 class="mb-1">Take The Risk Book</h6>
                                          <p class="font-size-13 line-height mb-1">Smith goal</p>
                                          <div class="d-block">
                                             <span class="font-size-13 text-warning">
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             </span>
                                          </div>
                                       </div>
                                       <!-- <div class="price d-flex align-items-center">
                                          <span class="pr-1 old-price">$120</span>
                                          <h6><b>$99</b></h6>
                                       </div> -->
                                       <div class="iq-product-action">
                                          <!-- <a href="javascript:void();"><i class="ri-shopping-cart-2-fill text-primary"></i></a> -->
                                          <a href="javascript:void();" class="ml-2"><i class="ri-heart-fill text-danger"></i></a>
                                       </div>
                                    </div>
                                 </li>

                                 <li class="d-flex align-items-center">
                                    <div class="col-5 p-0 position-relative image-overlap-shadow">
                                       <a href="javascript:void();"><img class="img-fluid rounded w-100" src="../../assets/images/trendy-books/05.jpg" alt=""></a>
                                       <div class="view-book">
                                          <router-link to="book-page" class="btn btn-sm btn-white">View Book</router-link>
                                       </div>
                                    </div>
                                    <div class="col-7">
                                       <div class="mb-2">
                                          <h6 class="mb-1">The Raze Night Book </h6>
                                          <p class="font-size-13 line-height mb-1">Paige Turner</p>
                                          <div class="d-block">
                                             <span class="font-size-13 text-warning">
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             </span>
                                          </div>
                                       </div>
                                       <!-- <div class="price d-flex align-items-center">
                                          <span class="pr-1 old-price">$150</span>
                                          <h6><b>$129</b></h6>
                                       </div> -->
                                       <div class="iq-product-action">
                                          <!-- <a href="javascript:void();"><i class="ri-shopping-cart-2-fill text-primary"></i></a> -->
                                          <a href="javascript:void();" class="ml-2"><i class="ri-heart-fill text-danger"></i></a>
                                       </div>
                                    </div>
                                 </li>

                                 <li class="d-flex align-items-center">
                                    <div class="col-5 p-0 position-relative image-overlap-shadow">
                                       <a href="javascript:void();"><img class="img-fluid rounded w-100" src="../../assets/images/trendy-books/06.jpg" alt=""></a>
                                       <div class="view-book">
                                          <router-link to="book-page" class="btn btn-sm btn-white">View Book</router-link>
                                       </div>
                                    </div>
                                    <div class="col-7">
                                       <div class="mb-2">
                                          <h6 class="mb-1">Find the Wave Book..</h6>
                                          <p class="font-size-13 line-height mb-1">Barb Ackue</p>
                                          <div class="d-block">
                                             <span class="font-size-13 text-warning">
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             <i class="fa fa-star"></i>
                                             </span>
                                          </div>
                                       </div>
                                       <!-- <div class="price d-flex align-items-center">
                                          <span class="pr-1 old-price">$120</span>
                                          <h6><b>$100</b></h6>
                                       </div> -->
                                       <div class="iq-product-action">
                                          <!-- <a href="javascript:void();"><i class="ri-shopping-cart-2-fill text-primary"></i></a> -->
                                          <a href="javascript:void();" class="ml-2"><i class="ri-heart-fill text-danger"></i></a>
                                       </div>
                                    </div>
                                 </li>
                           </Slick>
                        </template>
                     </iq-card>
                  </b-col>
                  <b-col lg="12">
                     <iq-card className="iq-card iq-card-block iq-card-stretch iq-card-height" headerClass="align-items-center position-relative" bodyClass="favorites-contens">
                        <template v-slot:headerTitle>
                              <h4 class="card-title mb-0">Favorite Reads</h4>
                           </template>
                           <template v-slot:headerAction>
                              <router-link to="/category-page" class="btn btn-sm btn-primary view-more text-white">View More</router-link>
                           </template>
                        <template v-slot:body>
                        <Slick id="favorites-slider" :option="option3">
                                 <li class="d-flex justify-content-between align-items-center">
                                    <div class="col-5 p-0 position-relative">
                                       <a href="#">
                                          <img src="../../assets/images/favorite/05.jpg" class="img-fluid rounded w-100" alt="">
                                       </a>
                                    </div>
                                    <div class="col-7">
                                       <h5 class="mb-2">Every Book is a new Wonderful Travel..</h5>
                                       <p class="mb-2">Author : Pedro Araez</p>
                                       <div class="d-flex justify-content-between align-items-center text-dark font-size-13">
                                          <span>Reading</span>
                                          <span class="mr-4">78%</span>
                                       </div>
                                       <div class="iq-progress-bar-linear d-inline-block w-100">
                                          <b-progress :value="78" class="iq-progress-bar" variant="primary" />
                                       </div>
                                       <a href="#" class="text-dark">Read Now<i class="ri-arrow-right-s-line"></i></a>
                                    </div>
                                 </li>

                                 <li class="d-flex justify-content-between align-items-center">
                                    <div class="col-5 p-0 position-relative">
                                       <a href="#">
                                          <img src="../../assets/images/favorite/06.jpg" class="img-fluid rounded w-100" alt="">
                                       </a>
                                    </div>
                                    <div class="col-7">
                                       <h5 class="mb-2">Casey Christie night book into find...</h5>
                                       <p class="mb-2">Author : Michael klock</p>
                                       <div class="d-flex justify-content-between align-items-center text-dark font-size-13">
                                          <span>Reading</span>
                                          <span class="mr-4">78%</span>
                                       </div>
                                       <div class="iq-progress-bar-linear d-inline-block w-100">
                                          <b-progress :value="78" class="iq-progress-bar" variant="danger" />
                                       </div>
                                       <a href="#" class="text-dark">Read Now<i class="ri-arrow-right-s-line"></i></a>
                                    </div>
                                 </li>

                                 <li class="d-flex justify-content-between align-items-center">
                                    <div class="col-5 p-0 position-relative">
                                       <a href="#">
                                          <img src="../../assets/images/favorite/07.jpg" class="img-fluid rounded w-100" alt="">
                                       </a>
                                    </div>
                                    <div class="col-7">
                                       <h5 class="mb-2">The Secret to English Busy People..</h5>
                                       <p class="mb-2">Author : Daniel Ace</p>
                                       <div class="d-flex justify-content-between align-items-center text-dark font-size-13">
                                          <span>Reading</span>
                                          <span class="mr-4">78%</span>
                                       </div>
                                       <div class="iq-progress-bar-linear d-inline-block w-100">
                                          <b-progress :value="78" class="iq-progress-bar" variant="info" />
                                       </div>
                                       <a href="#" class="text-dark">Read Now<i class="ri-arrow-right-s-line"></i></a>
                                    </div>
                                 </li>

                                 <li class="d-flex justify-content-between align-items-center">
                                    <div class="col-5 p-0 position-relative">
                                       <a href="#">
                                          <img src="../../assets/images/favorite/08.jpg" class="img-fluid rounded w-100" alt="">
                                       </a>
                                    </div>
                                    <div class="col-7">
                                       <h5 class="mb-2">The adventures of Robins books...</h5>
                                       <p class="mb-2">Author : Luka Afton</p>
                                       <div class="d-flex justify-content-between align-items-center text-dark font-size-13">
                                          <span>Reading</span>
                                          <span class="mr-4">78%</span>
                                       </div>
                                       <div class="iq-progress-bar-linear d-inline-block w-100">
                                          <b-progress :value="78" class="iq-progress-bar" variant="success" />
                                       </div>
                                       <a href="#" class="text-dark">Read Now<i class="ri-arrow-right-s-line"></i></a>
                                    </div>
                                 </li>
                        </Slick>
                        </template>
                     </iq-card>
                  </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
// import ApexChart from '../../components/core/charts/ApexChart'
import { mapGetters } from 'vuex'
export default {
  name: 'Dashboard',
  mounted () {
    core.index()
  },
  computed: {
    ...mapGetters({
      lang: 'Setting/langState'
    })
  },
  watch: {
  },
  methods: {
  },
  data () {
    return {
      chart1: {
        series: [{
          name: 'Net Profit',
          data: [44, 55, 57, 56, 61, 58, 63]
        }],
        chart: {
          type: 'bar',
          height: 200
        },
        colors: ['rgb(13, 214, 184)'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['s', 'm', 't', 'w', 't', 'f', 's']
        },
        yaxis: {
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return '$ ' + val + ' thousands'
            }
          }
        }
      },
      waveChart7: {
        chart: {
          height: 70,
          type: 'area',
          animations: {
            enabled: !0,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1e3
            }
          },
          toolbar: {
            show: !1
          },
          sparkline: {
            enabled: !0
          },
          group: 'sparklines'
        },
        dataLabels: {
          enabled: !1
        },
        stroke: {
          curve: 'smooth'
        },
        series: [{
          data: [44, 55, 57, 56, 61, 58, 63]
        }],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime',
          range: 777600000
        },
        yaxis: {
          max: 100
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: !1,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          }
        },
        legend: {
          show: !1
        },
        colors: ['var(--iq-primary)']
      },
      option2: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        centerMode: false,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      option3: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        centerMode: false,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  }
}
</script>
